import React, { useState, useEffect, useRef } from 'react';
import YouTube from 'react-youtube';
import './App.css';

function App() {
  const [audioUrl, setAudioUrl] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [videoId, setVideoId] = useState(null);
  const [audioId, setAudioId] = useState(null);
  const [shareUrl, setShareUrl] = useState(null);
  const [audioStartTime, setAudioStartTime] = useState(0);
  const [videoStartTime, setVideoStartTime] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [videoReady, setVideoReady] = useState(false);
  const [audioReady, setAudioReady] = useState(false);
  const [playersReady, setPlayersReady] = useState(false);

  const audioPlayer = useRef(null);
  const videoPlayer = useRef(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const audioParam = params.get('a');
    const videoParam = params.get('v');
    const audioTimeParam = params.get('at');
    const videoTimeParam = params.get('vt');
    if (audioParam) setAudioUrl(`https://www.youtube.com/watch?v=${audioParam}&t=${audioTimeParam}`);
    if (videoParam) setVideoUrl(`https://www.youtube.com/watch?v=${videoParam}&t=${videoTimeParam}`);
  }, []);

  useEffect(() => {
    if (videoReady && audioReady) {
      setPlayersReady(true);
    }
  }, [videoReady, audioReady]);

  useEffect(() => {
    if (playersReady && !playing) {
      const params = new URLSearchParams(window.location.search);
      const audioParam = params.get('a');
      const videoParam = params.get('v');
      if (audioParam && videoParam) {
        handlePlayPause();
      }
    }
  }, [playersReady]);

  const handlePlayPause = () => {
    if (!videoId || !audioId) {
      const newAudioId = getVideoId(audioUrl);
      const newVideoId = getVideoId(videoUrl);
      const newAudioStartTime = getStartTime(audioUrl);
      const newVideoStartTime = getStartTime(videoUrl);

      if (newAudioId && newVideoId) {
        setAudioId(newAudioId);
        setVideoId(newVideoId);
        setAudioStartTime(newAudioStartTime);
        setVideoStartTime(newVideoStartTime);

        if (audioPlayer.current && videoPlayer.current) {
          audioPlayer.current.internalPlayer.loadVideoById({ videoId: newAudioId, startSeconds: newAudioStartTime });
          videoPlayer.current.internalPlayer.loadVideoById({ videoId: newVideoId, startSeconds: newVideoStartTime });
          videoPlayer.current.internalPlayer.mute();
          setPlaying(true);
        }
      }
    } else {
      if (!playing) {
        audioPlayer.current.internalPlayer.playVideo();
        videoPlayer.current.internalPlayer.playVideo();
      } else {
        audioPlayer.current.internalPlayer.pauseVideo();
        videoPlayer.current.internalPlayer.pauseVideo();
      }
      setPlaying(!playing);
    }
  };

  const handleShare = () => {
    const baseUrl = window.location.origin;
    const shareLink = `${baseUrl}?a=${encodeURIComponent(getVideoId(audioUrl))}&v=${encodeURIComponent(getVideoId(videoUrl))}&at=${getStartTime(audioUrl)}&vt=${getStartTime(videoUrl)}`;
    setShareUrl(shareLink);
  };

  const getVideoId = (url) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?youtu(?:\.be\/|be\.com\/watch\?v=)([\w\-]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const getStartTime = (url) => {
    const regex = /(?:\?|&)t=(\d+)/;
    const match = url.match(regex);
    return match ? parseInt(match[1], 10) : 0;
  };

  const onAudioReady = (event) => {
    setAudioReady(true);
  };

  const onVideoReady = (event) => {
    setVideoReady(true);
  };

  return (
    <div className="App">
      <h1>v0.9</h1>
      <div>
        <label htmlFor="audio">Audio:</label>
        <input
          type="text"
          id="audio"
          value={audioUrl}
          onChange={(e) => setAudioUrl(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="video">Video:</label>
        <input
          type="text"
          id="video"
          value={videoUrl}
          onChange={(e) => setVideoUrl(e.target.value)}
        />
      </div>
      <button onClick={handlePlayPause}>{playing ? 'Pause' : 'Play'}</button>
      {videoId && (
        <>
          <div style={{ display: 'none' }}>
            <YouTube
              ref={audioPlayer}
              videoId={audioId}
              opts={{ playerVars: { autoplay: 0, start: audioStartTime, playsinline: 1 } }}
              onReady={onAudioReady}
              webkit-playsinline="1"
            />
          </div>
          <div>
            <YouTube
              ref={videoPlayer}
              videoId={videoId}
              opts={{ playerVars: { autoplay: 0, start: videoStartTime, mute: 1 } }}
              onReady={onVideoReady}
            />
            <button onClick={handleShare}>Share</button>
            {shareUrl && (
              <div>
                <p>Copy and share this link:</p>
                <a href={shareUrl} target="_blank" rel="noopener noreferrer">
                  {shareUrl}
                </a>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default App;